import React from 'react'
import api from '../helpers/api'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import ListTeam from '../components/ListTeam'
import { Link } from 'gatsby'
import { dates } from '../constants'

class Tymy extends React.Component {
    constructor() {
        super();

        this.state = {
            teams: [],
        }
    }

    componentDidMount() {
        api.get( '/teams', ( response ) => {
            const data = response.data;
            this.setState({
                teams: data
            })
        } );
    }

    render() {
        return (
            <Layout>
                <SEO
                title="Registrované týmy"
                description={`Týmy registrované na hru Navíc, která se koná v Praze ${dates.game}.`}
                />

                <div id="main">
                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h2>Registrované týmy</h2>
                            </header>

                            <p>Informace o placení a možnost editovat své údaje najdete na stránce <Link to="/vas-tym">Váš tým</Link>. Je potřeba, aby startovné dorazilo na náš účet do {dates.payment}, neotálejte proto s platbou.</p>

							<p id="paid__info">Zaplatilo už {this.state.teams.reduce((a, b) => {
                                return parseInt(a, 10) + parseInt(b.paid, 10)
                            }, 0)} týmů.</p>

                            {this.state.teams.map( (team, i) => {
                                return <ListTeam key={`team-` + team.id} data={team} index={i} />
                            })}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default Tymy
